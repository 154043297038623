import Vue from 'vue'
import Vuex from 'vuex'
import { eventBus } from '../main'
import { router } from '../router'
import { EVT_LOGIN } from '../components/constants'
import {
  EMPLOYEES, IN_PRINT, INITIAL_AUTH,
  LOGIN_DONE, LOGOUT_DONE, OFFLINE, ONLINE,
  SET_ADMIN, SET_LOGGED_IN, SET_LOGGED_OUT,
  SHOW_LOGIN, USER_LOGOUT, WATCHERS, WEBSOCKETS, WSS_ONLINE,
  CARDS, HTTP
} from './store_constants'

Vue.use(Vuex)

export const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',

  state: {
    // state for login
    loggedIn: false,
    initialAuth: false, // have we made initial attempt to check login status
    showLogin: false, // show the login form
    online: false,
    wssOnline: false,

    // state for logged-in admin user
    fullName: '',
    is_system: false,
    is_bookkeeper: false,
    is_admin: false,
    is_manager: false,
    companyName: '',
    timezone: '',
    inAdmin: false,

    // state for employees
    employees: [],
    activeEmployees: [],

    // for printing
    inPrint: false,

    // for websockets2 related to system uses
    watchers: [],
    websockets: [],
    cards: [],
    http: []
  },

  getters: {
    showLogin: state => {
      return (state.showLogin && state.initialAuth)
    },
    loggedIn: state => {
      return (state.loggedIn && state.initialAuth)
    },
    initialAuth: state => {
      return state.initialAuth
    },
    online: state => {
      return state.online
    },
    wssOnline: state => {
      return state.wssOnline
    },


    fullName: state => {
      return state.fullName
    },
    is_system: state => {
      return state.is_system
    },
    is_bookkeeper: state => {
      return state.is_bookkeeper
    },
    is_admin: state => {
      return state.is_admin
    },
    is_manager: state => {
      return state.is_manager
    },
    companyName: state => {
      return state.companyName
    },
    inAdmin: state => {
      return state.inAdmin
    },

    employees: state => {
      return state.employees
    },
    activeEmployees: state => {
      return state.activeEmployees
    },

    inPrint: state => {
      return state.inPrint
    },

    watchers: state => {
      return state.watchers
    },
    websockets: state => {
      return state.websockets
    },
    cards: state => {
      return state.cards
    },
    http: state => {
      return state.http
    }
  },

  mutations: {
    [SHOW_LOGIN]: (state, payload) => {
      state.showLogin = payload
    },
    [ONLINE]: state => {
      state.online = true
    },
    [OFFLINE]: state => {
      state.online = false
      state.wssOnline = false
    },
    [WSS_ONLINE]: state => {
      state.wssOnline = true
    },

    // mutations for login & registration
    [INITIAL_AUTH]: (state) => {
      state.initialAuth = true
    },

    [SET_LOGGED_IN]: (state, payload) => {
      console.log("Commit login.")
      state.loggedIn = true
      state.showLogin = false
      // store user and company info
      state.is_system = payload.is_system
      state.is_bookkeeper = payload.is_bookkeeper
      state.is_admin = payload.is_admin
      state.is_manager = payload.is_manager
      state.fullName = payload.first_name + ' ' + payload.last_name
      state.companyName = payload.co_name
      state.timezone = payload.timezone
      eventBus.$emit(EVT_LOGIN)
    },
    [SET_LOGGED_OUT]: state => {
      console.log("Commit logout.")
      state.loggedIn = false
      // clear everything related to user and company
      state.is_system = false
      state.is_bookkeeper = false
      state.is_admin = false
      state.is_manager = false
      state.fullName = ''
      state.co_name = ''
      state.timezone = ''
      // eventBus.$emit(EVT_LOGOUT)
      // TODO clear things like employees[]
      state.employees = []
      state.activeEmployees = []
      state.watchers = []
      state.websockets = []
      state.http = []
      state.cards = []
    },

    [SET_ADMIN]: (state, payload) => {
      state.inAdmin = payload
    },

    [EMPLOYEES]: (state, payload) => {
      state.employees = payload
      // console.log("Employees:", payload)
      let active = []
      for (var i = 0; i < payload.length; i++) {
        if (payload[i].active) {
          active.push(payload[i])
        }
      }
      state.activeEmployees = active
    },

    [IN_PRINT]: (state, payload) => {
      state.inPrint = payload
    },

    // for the websockets2 related to system use
    [WATCHERS]: (state, payload) => {
      state.watchers = payload
    },
    [WEBSOCKETS]: (state, payload) => {
      state.websockets = payload
    },
    [CARDS]: (state, payload) => {
      state.cards = payload
    },
    [HTTP]: (state, payload) => {
      state.http = payload
    }
  },

  actions: {
    [LOGIN_DONE]: (context, payload) => {
      context.commit(SET_LOGGED_IN, payload)
      if (!context.state.inAdmin) {
        router.push('/admin') // TODO will eventually be the dashboard page
      }
    },
    [LOGOUT_DONE]: context => {
      context.commit(SET_LOGGED_OUT)
      context.commit(OFFLINE)
      if (context.state.inAdmin) {
        router.push('/logout')
      }
    },
    [USER_LOGOUT]: context => {
      context.commit(SET_LOGGED_OUT)
      context.commit(OFFLINE)
      if (context.state.inAdmin) {
        router.push('/')
      }
    }
  }
})
