<template>
  <v-app>
    <router-view/>

    <div v-if="!inPrint">
      <login v-if="showLogin"></login>
      <v-alert :value="(errorMessage!='')" outlined type="error">
        {{ errorMessage }}
      </v-alert>
    </div>
  </v-app>
</template>

<script>
  import { eventBus } from './main'
  import Login from './components/Login'
  import { EVT_SHOW_GLOBAL_ALERT } from './components/constants'
  import network from './components/network'

  export default {
    name: 'App',
    components: { Login },

    created () {
      console.log('Created:', this.$options.name)
      network.refreshLogin()

      eventBus.$on(EVT_SHOW_GLOBAL_ALERT, (alert) => {
        console.log('Show global alert:', alert)
        this.errorMessage = alert
        setTimeout(() => (this.errorMessage = ''), 5000)
      })
    },

    computed: {
      showLogin () {
        return this.$store.getters.showLogin
      },
      inPrint () {
        return this.$store.getters.inPrint
      }
    },

    methods: {},
    data () {
      return {
        errorMessage: ''
      }
    }
  }
</script>

<style scoped>

</style>
