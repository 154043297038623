import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueRouter from 'vue-router'
import {router} from './router'
import {store} from './store/store'
import {BASE_URL, WS_URL} from './constants'
import Vuelidate from 'vuelidate'

// Vue.config.productionTip = false // uncomment for dev mode, comment out for prod

axios.defaults.baseURL = BASE_URL
axios.defaults.withCredentials = true

console.log("Mode:", process.env.NODE_ENV)
console.log("URL: ", BASE_URL)
console.log("URL: ", WS_URL)

export const eventBus = new Vue()

Vue.use(VueRouter)
Vue.use(Vuelidate)

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
