<template>
  <v-overlay :value="message">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
    {{ message }}
  </v-overlay>
</template>

<script>
  export default {
    name: "Busy",

    props: {
      message: { type: String, Required: true }
    }
  }
</script>

<style scoped>

</style>
