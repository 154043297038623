import { store } from '../store/store'
import {
  INITIAL_AUTH, LOGIN_DONE, LOGOUT_DONE, ONLINE, OFFLINE, USER_LOGOUT
} from '../store/store_constants'
import axios from 'axios'
import { eventBus } from '../main'
import { EVT_SHOW_GLOBAL_ALERT } from './constants'
import wsConnection from './websockets'

class Network {
  constructor () {
    // start a periodic timer to keep connections alive
    console.log("Network constructor.")
    setInterval(this.checkConnections, 5000)
  }

  //---------------------------------------------------------------------------
  checkConnections () {
    if (!store.getters.initialAuth) {
      network.refreshLogin()
      return
    }
    if (!store.getters.loggedIn) {
      return // not logged in, nothing to do
    }

    // initial auth attempt is done, and we're logged in
    // try to get us back online (verify our login is still good)
    if (!store.getters.online) {
      network.checkLogin()
      return
    }
    // try to get wss back online
    if (!store.getters.wssOnline) {
      wsConnection.connect()
    }
  }

  //---------------------------------------------------------------------------
  login (credentials, success, failure) {
    console.log('Login.')
    axios.post('/login', credentials)
        .then(res => {
          console.log('Login succeeded:', res.status, res.data)
          store.commit(INITIAL_AUTH)
          store.commit(ONLINE)
          store.dispatch(LOGIN_DONE, res.data)
          wsConnection.connect()
          success() // success callback
        })
        .catch(err => {
          console.log(`*Login failed: ${err.toString()}`)
          store.commit(INITIAL_AUTH)
          store.commit(OFFLINE)
          store.dispatch(LOGOUT_DONE)
          wsConnection.close()
          failure(err.toString())
        })
  }

  refreshLogin () {
    // verify logged-in, refresh session, get our "vitals" from server
    console.log('Refresh login.')
    axios.get('/refresh')
        .then(res => {
          console.log('Logged in:', res.status, res.request.responseURL, res.data)
          store.commit(INITIAL_AUTH)
          store.commit(ONLINE)
          store.dispatch(LOGIN_DONE, res.data)
          wsConnection.connect()
        })
        .catch(err => {
          store.commit(INITIAL_AUTH)
          network.checkErr(err, true)
        })
  }

  checkLogin () {
    // verify logged-in, but without refreshing session
    // allows programmatic login check, but without artificially extending session
    console.log('Check login.')
    axios.get('/poke')
        .then(res => {
          console.log('Logged in:', res.status, res.request.responseURL, res.data)
          store.commit(ONLINE)
          wsConnection.connect()
        })
        .catch(err => {
          network.checkErr(err, true)
        })
  }

  //---------------------------------------------------------------------------
  quickCheck () {
    // only for testing, changes no state
    console.log('Quick check login.')
    axios.get('/poke')
        .then(res => {
          console.log('Logged in:', res.status, res.request.responseURL, res.data)
        })
        .catch(err => {
          network.checkErr(err)
        })
  }

  //---------------------------------------------------------------------------
  checkCodes () {
    // only for testing, changes no state
    console.log('Check response codes.')
    let codes = ['200', '400', '403', '500']
    for (const code of codes) {
      axios.get(`/code/${code}`)
          .then(res => {
            console.log('Logged in:', res.status, res.request.responseURL)
          })
          .catch(err => {
            network.checkErr(err)
          })
    }
  }

  checkErr (err, notify = false) {
    if (notify) {
      store.commit(OFFLINE)
      wsConnection.close()
    }
    if (err.response) {
      console.log('Err:', err.response.status,
          err.response.request.responseURL, err.response.data)
      if (err.response.status == 403) {
        console.log('Not logged in.')
        if (notify) {
          store.dispatch(LOGOUT_DONE)
        }
        return
      }
    }
    console.log(`Login unsure: ${err.toString()}`)
  }

  //---------------------------------------------------------------------------
  logout () {
    console.log('Logout.')
    store.dispatch(USER_LOGOUT)
    wsConnection.close()
    axios.put('/logout')
        .then(res => {
          console.log('Logout successful: ', res)
        })
        .catch(err => {
          let msg = `Logout failed. Please try again later. ${err.toString()}`
          console.log(msg)
          eventBus.$emit(EVT_SHOW_GLOBAL_ALERT, msg)
        })
  }
}

//-----------------------------------------------------------------------------
// instantiate singleton
const network = new Network()
Object.freeze(network)

export default network
