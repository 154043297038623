import { store } from './store/store'
import VueRouter from 'vue-router'
import { SET_ADMIN, SHOW_LOGIN } from './store/store_constants'

const routes = [
  {
    path: '/admin',
    component: () => import('./components/Admin/Admin'),
    beforeEnter: checkLogin,
    children: [
      { path: '', redirect: '/admin/employees' },
      {
        path: 'employees',
        component: () => import('./components/Admin/Employees/Employees'),
        beforeEnter: checkLogin
      },
      {
        path: 'payroll',
        component: () => import('./components/Admin/Payroll/Payroll'),
        beforeEnter: checkLogin
      },
      {
        path: 'payroll/archives',
        component: () => import('./components/Admin/Archives'),
        beforeEnter: checkLogin
      },
      {
        path: 'payroll/all',
        component: () => import('./components/Admin/AllPayrolls'),
        beforeEnter: checkLogin
      },
      {
        path: 'payroll/:which',
        component: () => import('./components/Admin/Payroll/Payroll'),
        beforeEnter: checkLogin
      },

      {
        path: 'tablets',
        component: () => import('./components/Admin/Devices'),
        beforeEnter: checkLogin
      },
      {
        path: 'users',
        component: () => import('./components/Admin/Users/Users'),
        beforeEnter: checkLogin
      },
      {
        path: 'sessions',
        component: () => import('./components/Admin/Sessions/Sessions'),
        beforeEnter: checkLogin
      }
    ]
  },
  {
    path: '/',
    component: () => import('./components/Home'),
    beforeEnter: setNoAdmin,
    children: [
      {
        path: 'logout',
        component: () => import('./components/Logout'),
        beforeEnter: setNoAdmin
      }
    ]
  },
  { path: '*', redirect: '/' }
]

export const router = new VueRouter({
  routes,
  mode: 'history'
})

function setNoAdmin (to, from, next) {
  console.log('Non admin path, logged-in:', store.getters.loggedIn)
  store.commit(SET_ADMIN, false)
  store.commit(SHOW_LOGIN, false)
  next()
}

function checkLogin (to, from, next) {
  console.log('Admin path, check logged-in status:', store.getters.loggedIn)
  store.commit(SET_ADMIN, true)
  // if not logged in, show login over whatever admin screen is showing
  if (!store.getters.loggedIn) {
    store.commit(SHOW_LOGIN, true)
  }
  next()
}
