// Actions for the store
export const LOGIN_DONE = 'login_done'
export const LOGOUT_DONE = 'logout_done'
export const USER_LOGOUT = 'user_logout'

export const SHOW_LOGIN = 'showLoginScreen'

export const ONLINE = 'online'
export const OFFLINE = 'offline'
export const WSS_ONLINE = 'wss_online'

// Commits for the store
export const SET_ADMIN = 'setAdmin'
export const IN_PRINT = 'inPrint'
export const EMPLOYEES = 'employees'
export const INITIAL_AUTH = 'initial_auth'
export const SET_LOGGED_IN = 'set_logged_in'
export const SET_LOGGED_OUT = 'set_logged_out'

// for websockets related to system uses
export const WATCHERS = 'watchers'
export const WEBSOCKETS = 'websockets'
export const CARDS = 'cards'
export const HTTP = 'http'

