<template>
  <v-dialog max-width="390" persistent v-model="showLogin">
    <v-card>
      <form>
        <v-card-title class="headline">Login to SimpleTimeCards.com</v-card-title>

        <v-card-text>
          <v-container>
            <v-row dense>
              <v-text-field
                      :error-messages="usernameErrors"
                      @blur="$v.username.$touch()"
                      @input="$v.username.$touch()"
                      autocomplete="username email"
                      filled
                      label="Email"
                      type="text"
                      v-model="username"
              ></v-text-field>
            </v-row>
            <v-row dense>
              <v-text-field
                      :error-messages="passwordErrors"
                      :type="showPassword ? 'text' : 'password'"
                      @blur="$v.password.$touch()"
                      @input="$v.password.$touch()"
                      autocomplete="current-password"
                      class="pb-0 mb-0"
                      filled
                      label="Password"
                      v-model="password"
              ></v-text-field>
            </v-row>
            <v-row no-gutters>
              <v-switch
                      class="py-0 my-0"
                      color="primary"
                      dense
                      label="Show password"
                      v-model="showPassword"
              ></v-switch>
            </v-row>
          </v-container>

          <v-alert :value="(errorMessage!='')" outlined type="error">
            {{ errorMessage }}
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <v-row justify="space-around">
                  <v-btn @click="done" color="blue darken-1" text>Cancel</v-btn>
                  <v-btn :disabled="$v.$invalid" @click="login"
                         color="blue darken-1" text>
                    Login
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </form>
    </v-card>

    <busy :message="busyMessage"></busy>
  </v-dialog>
</template>

<script>
  import Busy from './Admin/Busy'
  import network from './network'
  import { SHOW_LOGIN } from '../store/store_constants'
  import { required, email } from 'vuelidate/lib/validators'

  export default {
    name: 'Login',
    components: { Busy },

    created () {
      console.log('Created:', this.$options.name)
      this.clear()
      if (process.env.NODE_ENV === 'development') {
        this.username = 'michael@redmule.com' // TODO - REMOVE!!!
        this.password = '4p2WAZ*MpXNt5'
      }
    },
    beforeDestroy () {
      this.clear()
    },

    computed: {
      showLogin () {
        return this.$store.getters.showLogin
      },
      usernameErrors () {
        const errors = []
        if (!this.$v.username.$dirty) return errors
        !this.$v.username.email && errors.push('Must be valid e-mail')
        !this.$v.username.required && errors.push('E-mail is required')
        return errors
      },
      passwordErrors () {
        const errors = []
        if (!this.$v.password.$dirty) return errors
        // !this.$v.password.required && errors.push('Password must not be blank')
        !this.$v.password.required && errors.push('Password must not be blank')
        return errors
      }
    },

    validations: {
      username: { required, email },
      password: { required }
    },

    methods: {
      login () {
        console.log('Attempt login.')
        let rec = {
          username: this.username,
          password: this.password
        }
        this.busyMessage = 'Logging in...'
        network.login(rec, this.done, this.failure)
      },

      failure (error) {
        this.busyMessage = ''
        this.showAlert(`Login failed: ${error}`)
      },
      done () {
        console.log("Login done.")
        this.clear()
        this.$store.commit(SHOW_LOGIN, false)
      },
      clear () {
        this.busyMessage = ''
        this.errorMessage = ''
        this.username = ''
        this.password = ''
        this.showPassword = false
        this.$v.$reset()
      },

      showAlert (msg) {
        this.errorMessage = msg
        setTimeout(() => (this.errorMessage = ''), 5000)
      }
    },

    data () {
      return {
        username: '',
        password: '',
        errorMessage: '',
        busyMessage: '',

        showPassword: false
      }
    }
  }
</script>

<style scoped>

</style>
