// Custom events
export const EVT_LOGIN = 'login'
export const EVT_LOGOUT = 'logout'
export const EVT_SHOW_GLOBAL_ALERT = 'showGlobalAlert'

export const EVT_FINISHED_EDIT_EMPLOYEE_HOURS = 'finishedEditHours'
export const EVT_EDIT_EMPLOYEE_HOURS = 'editEmployeeHours'
export const EVT_FINISHED_EDIT_HOURS_ITEM = 'finishedEditHoursItem'
export const EVT_EDIT_HOURS_ITEM = 'editHoursItem'

export const EVT_FINISHED_CLOCK_ALL_OUT = 'finishedClockAllOut'

export const EVT_EDIT_EMPLOYEE = 'editEmployee'

export const EVT_EDIT_USER = 'editUser'
export const EVT_SET_USER_PASSWORD = 'setUserPassword'
export const EVT_CONFIRM_DELETE_USER = 'confirmDeleteUser'
export const EVT_USER_DONE = 'userDone'

