export var BASE_URL
export var WS_URL
export var WS_URL_SYS

if (process.env.NODE_ENV === 'production') {

    // urls for production
    BASE_URL = 'https://simpletimecards.com/api/v1'
    WS_URL = 'wss://simpletimecards.com/api/v1/ws/employees/main'
    WS_URL_SYS = 'wss://simpletimecards.com/api/v1/ws/system'

    // urls for local development with Caddy (requires production build)
    // BASE_URL = 'http://localhost:2015/api/v1'
    // WS_URL = 'ws://localhost:2015/api/v1/ws/employees/main'
    // WS_URL_SYS = 'ws://localhost:2015/api/v1/ws/system'

} else {

    // urls for local development without Caddy
    BASE_URL = 'http://localhost:8223/api/v1'
    WS_URL = 'ws://localhost:8223/api/v1/ws/employees/main'
    WS_URL_SYS = 'ws://localhost:8223/api/v1/ws/system'

}
