import { EMPLOYEES, ONLINE, OFFLINE, WSS_ONLINE, WATCHERS, WEBSOCKETS, CARDS, HTTP } from '../store/store_constants'
import { WS_URL, WS_URL_SYS } from '../constants'
import { store } from '../store/store'


class WebSocketConn {
  constructor () {
    this._socket = null
  }

  //---------------------------------------------------------------------------
  connect () {
    console.log('Try wss connect.')

    // don't connect if already connected
    if (this._socket != null) {
      if (this._socket.readyState == WebSocket.OPEN ||
          this._socket.readyState == WebSocket.CONNECTING) {
        console.log("Websocket already running or connecting.")
        return
      }
    }
    store.commit(OFFLINE)

    let url = WS_URL
    if (store.getters.is_system) {
      url = WS_URL_SYS
    }

    try {
      console.log('Open wss:', url)
      this._socket = new WebSocket(url)
    } catch (err) {
      console.log("Wss err:", err)
    }

    this._socket.addEventListener('open', () => {
      console.log('Connected to wss.')
      store.commit(ONLINE)
      store.commit(WSS_ONLINE)
    })

    this._socket.addEventListener('message', (event) => {
      console.log('Wss message len:', Object.keys(event.data).length)
      // console.log('Wss message:', event)
      store.commit(ONLINE)
      store.commit(WSS_ONLINE)
      this.processMsg(event)
    })

    this._socket.addEventListener('close', (event) => {
      console.log('Wss closed:', event)
      store.commit(OFFLINE)
    })

    this._socket.addEventListener('error', (event) => {
      console.log("Wss error:", event)
      store.commit(OFFLINE)
    })
  }

  processMsg (event) {
    const msg = JSON.parse(event.data)
    // console.log("Msg:", msg)
    console.log("Msg:", msg.Topic, msg.Body)
    switch (msg.Topic) {
      case 'empl':
        store.commit(EMPLOYEES, msg.Body)
        break
      case 'watchers':
        store.commit(WATCHERS, msg.Body)
        break
      case 'websockets':
        store.commit(WEBSOCKETS, msg.Body)
        break
      case 'cards':
        store.commit(CARDS, msg.Body)
        break
      case 'http':
        store.commit(HTTP, msg.Body)
        break
      default:
        console.log("Unknown msg topic:", msg.topic)
        break
    }
  }

  //---------------------------------------------------------------------------
  close () {
    console.log('Disconnect wss.')
    store.commit(OFFLINE)
    if (this._socket != null) {
      this._socket.close()
    }
  }
}

//-----------------------------------------------------------------------------
const wsConnection = new WebSocketConn()

export default wsConnection
